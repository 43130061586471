<template>
	<div id="warning">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>管理</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">预警中心</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="branch-school u-f9">
					<div class="u-f-item u-f-jsb branch-school-title">
						<div>预警中心</div>
						<el-button size="small" style="width: 100px;" @click="editCampusShow = true">编辑预警</el-button>
					</div>
					<div class="branch-school-list">
						<div class="branch-school-item">
							<div class="u-f-item u-f-jsb" style="margin-bottom: 10px;">
								<div>
									教师请假预警
								</div>
								<div class="u-f-item">
									<div class="section">{{info.teacher_leave_warning}}天</div>
									<!-- <el-switch
									  v-model="value1"
									  active-text="开"
									  inactive-text="关">
									</el-switch> -->
								</div>
							</div>
							<div class="u-f-item u-f-jsb" style="margin-bottom: 10px;">
								<div>
									学生请假预警
								</div>
								<!-- <div class="section">{{info.student_leave_warning}}天</div> -->
								<div class="u-f-item">
									<div class="section">{{student_leave_warning}}天</div>
									<!-- <el-switch
									  v-model="value1"
									  active-text="开"
									  inactive-text="关">
									</el-switch> -->
								</div>
							</div>
							<div class="u-f-item u-f-jsb" style="margin-bottom: 10px;">
								<div>
									评价开始时间
								</div>
								<div class="section">{{info.evaluate_start_time}}</div>
							</div>
							<div class="u-f-item u-f-jsb" style="margin-bottom: 10px;">
								<div>
									评价结束时间
								</div>
								<div class="section">{{info.evaluate_end_time}}</div>
							</div>
							<!-- <div class="u-f-item u-f-jsb" style="margin-bottom: 10px;">
								<div>
									总课时量
								</div>
								<div class="section">{{info.course_num}}课时</div>
							</div> -->
						</div>
						
						<div class="branch-school-item">
							<div class="u-f-item u-f-jsb" style="margin-bottom: 10px;">
								<div>
									学生预警推送人
								</div>
							</div>
							<div class="list-warg">
								<div v-for="(item,index) in info.student_leave_warning_push_text" :key="index">{{item}}</div>
							</div>
						</div>
						
						<div class="branch-school-item">
							<div class="u-f-item u-f-jsb" style="margin-bottom: 10px;">
								<div>
									教师预警推送人
								</div>
							</div>
							<div class="list-warg">
								<div v-for="(item,index) in info.teacher_leave_warning_push_text" :key="index">{{item}}</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</index>
		
		<el-dialog title="编辑预警" :visible.sync="editCampusShow" width="500px" :before-close="handleClose">
			
			<div class="edit-box" style="max-height: 500px;overflow-y: auto;">
				<div class="u-f input-box">
					<div class="input-lebal" style="margin-top: 10px;">
						教师请假预警
					</div>
					<div style="width: 65%;">
						<el-input v-model="teacher_leave_warning" placeholder="请输入时长"></el-input>
						<div style="color: red;font-size: 12px;padding: 5px 0 0 5px;">输入-1关闭预警</div>
					</div>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						学生请假预警
					</div>
					<div style="width: 65%;">
						<el-input v-model="student_leave_warning" placeholder="请输入时长"></el-input>
						<div style="color: red;font-size: 12px;padding: 5px 0 0 5px;">输入-1关闭预警</div>
					</div>
					
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						评价开始时间
					</div>
					 <el-date-picker v-model="evaluate_start_time" type="datetime" style="width: 65%;" placeholder="选择日期" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						评价结束时间
					</div>
					<el-date-picker v-model="evaluate_end_time" type="datetime" style="width: 65%;" placeholder="选择日期" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				</div>
				<div class="u-f input-box">
					<div class="input-lebal" style="margin-top: 10px;">
						学生预警推送人
					</div>
					<el-select v-model="student_leave_warning_push" multiple style="width: 65%;" filterable>
						<el-option v-for="(item, index) in selectList" :key="index" :label="item.username" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="u-f input-box">
					<div class="input-lebal" style="margin-top: 10px;">
						老师预警推送人
					</div>
					<el-select v-model="teacher_leave_warning_push" multiple style="width: 65%;" filterable>
						<el-option v-for="(item, index) in selectList" :key="index" :label="item.username" :value="item.id"></el-option>
					</el-select>
				</div>
				<!-- <div class="u-f-item input-box">
					<div class="input-lebal">
						总课时量
					</div>
					<el-input v-model="course_num" placeholder="请输入时长" style="width: 65%;"></el-input>
				</div> -->
			</div>
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="editCampusShow = false" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="editCampus">确 定</el-button>
			</span>
		</el-dialog>
		
	</div>
</template>

<script>
	import index from '@/components/common/index.vue';
	export default {
		components: {
			index
		},
		data() {
			return {
				info:'',
				editCampusShow:false,
				teacher_leave_warning:'',
				student_leave_warning:'',
				evaluate_start_time:'',
				evaluate_end_time:'',
				course_num:'',
				selectList: [],
				student_leave_warning_push:[],
				teacher_leave_warning_push:[]
			}
		},
		mounted() {
			this.getSchoolInfo()
			this.userList()
		},
		methods:{
			// 人员列表
			userList() {
				this.$api.setting.userList({}).then(res => {
					if (res.data.code == 1) {
						this.selectList = res.data.data.rows;
					}
				});
			},
			// 获取学校信息
			getSchoolInfo(){
				this.$api.setting.schoolInfo().then(res=>{
					if(res.data.code==1){
						let data = res.data.data;
						this.info = data;
						this.teacher_leave_warning = this.info.teacher_leave_warning;
						this.student_leave_warning = this.info.student_leave_warning;
						this.evaluate_start_time = this.info.evaluate_start_time;
						this.evaluate_end_time = this.info.evaluate_end_time;
						this.course_num = this.info.course_num;
						if(this.info.student_leave_warning_push){
							let arr = this.info.student_leave_warning_push.split(",");
							for(let i in arr){
								arr[i] = Number(arr[i])
							}
							this.student_leave_warning_push = arr;
						}
						if(this.info.teacher_leave_warning_push){
							let arr = this.info.teacher_leave_warning_push.split(",");
							for(let i in arr){
								arr[i] = Number(arr[i])
							}
							this.teacher_leave_warning_push = arr;
						}
					}
				})
			},
			handleClose(done) {
				done();
			},
			// 修改学校信息
			editCampus(){
				this.info.teacher_leave_warning = this.teacher_leave_warning;
				this.info.student_leave_warning = this.student_leave_warning;
				this.info.evaluate_start_time = this.evaluate_start_time;
				this.info.evaluate_end_time = this.evaluate_end_time;
				this.info.course_num = this.course_num;
				this.info.student_leave_warning_push = this.student_leave_warning_push;
				this.info.teacher_leave_warning_push = this.teacher_leave_warning_push;
				this.$api.setting.editSchool(this.info).then(res=>{
					if(res.data.code==1){
						this.editCampusShow = false;
						this.getSchoolInfo()
						this.$message.success('修改成功')
					}else{
						this.$message.error(res.data.msg)
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	#warning{
		.content{
			background-color: #ffffff;
			height: 85vh;
			margin-top: 15px;
			.branch-school {
				.branch-school-title {
					background-color: #e4edff;
					padding: 15px 40px;
				}
				.branch-school-list {
					padding: 30px 40px;
					display: flex;
					flex-wrap: wrap;
					.branch-school-item {
						border: 1px solid #eeeeee;
						background-color: #FFFFFF;
						width: 400px;
						margin-right: 20px;
						margin-bottom: 20px;
						border-radius: 3px;
						padding: 20px;
						.section {
							color: #aaa;
							display: inline-block;
							padding: 3px 10px;
						}
						.meun {
							padding: 10px 0;
							background-color: #fafafa;
							color: #8d9da6;
							div {
								border-right: 1px solid #e8eaec;
								cursor: pointer;
								i {
									margin-right: 3px;
								}
							}
							div:last-child {
								border-right: none;
							}
							.meun-item:hover {
								color: #ed6335;
							}
						}
						.list-warg{
							display: flex;
							flex-wrap: wrap;
							div{
								background-color: #F1F1F1;
								padding: 2px 10px;
								margin-right: 5px;
								color: #A4A4A4;
								margin-bottom: 5px;
							}
						}
					}
					div:nth-child(3n) {
						margin-right: 0;
					}
				}
			}
		}
		.el-dialog__header {
			border-bottom: 1px solid #eeeeee;
			padding: 10px 20px;
			.el-dialog__title {
				font-size: 16px;
			}
		}
		.el-dialog__body {
			padding: 0 20px 0 20px;
			.edit-box {
				.input-box {
					margin-top: 35px;
					padding-left: 20px;
					.input-lebal {
						color: #aeb5b8;
						margin-right: 10px;
						width: 80px;
						span {
							color: #ff7a65;
							margin-right: 3px;
						}
					}
					.el-icon-remove-outline {
						font-size: 1.5625vw;
						color: #d7d9db;
						margin-left: 10px;
					}
				}
				.avatar-uploader .el-upload {
					border: 1px dashed #d9d9d9;
					border-radius: 6px;
					cursor: pointer;
					position: relative;
					overflow: hidden;
				}
				.avatar-uploader .el-upload:hover {
					border-color: #409eff;
				}
				.avatar-uploader-icon {
					font-size: 28px;
					color: #8c939d;
					width: 90px;
					height: 90px;
					line-height: 90px;
					text-align: center;
				}
				.avatar {
					width: 90px;
					height: 90px;
					display: block;
				}
			}
		}
		.el-dialog__footer {
			padding: 15px 20px 15px;
			box-shadow: 0px -5px 10px -5px #eeeeee;
		}
		
	}
</style>
